import { render, staticRenderFns } from "./Derive.vue?vue&type=template&id=3460e933&scoped=true&"
import script from "./Derive.vue?vue&type=script&lang=js&"
export * from "./Derive.vue?vue&type=script&lang=js&"
import style0 from "./Derive.vue?vue&type=style&index=0&id=3460e933&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3460e933",
  null
  
)

export default component.exports